import "react-loadingmask/dist/react-loadingmask.css";
import "react-day-picker/lib/style.css";
import "moment/locale/th";

import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from "react-day-picker/moment";
import {
  NavLink,
  BrowserRouter as Route,
  Switch,
  useHistory,
} from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import { api, header_token } from "../../config";

import Axios from "axios";
import DayPickerInput from "react-day-picker/DayPickerInput";
import LoadingMask from "react-loadingmask";
import { NotiContext } from "../../store/keep";
import { OrderCarCancel } from "./OrderCarCancel";
import { OrderCarCreate } from "./OrderCarCreate"
import { OrderCarHistory } from "./OrderCarHistory";
import { OrderCarNew } from "./OrderCarNew";
import { OrderCarOnprocess } from "./OrderCarOnprocess";
import { OrderCarPreOrder } from "./OrderCarPreOrder";
import { OrderCarReceive } from "./OrderCarReceive";
import { OrderCarSending } from "./OrderCarSending";
import { OrderCarSuccess } from "./OrderCarSuccess";
import { OrderCarUnsuccess } from "./OrderCarUnsuccess";
import moment from "moment";

function CustomOverlay({ classNames, selectedDay, children, ...props }) {
  return (
    <div
      className={classNames.overlayWrapper}
      style={{ marginLeft: -109 }}
      {...props}
    >
      <div className={classNames.overlay}>{children}</div>
    </div>
  );
}

export const OrderCarIndex = () => {
  const { push } = useHistory();
  const { noti } = useContext(NotiContext);

  const [data_preorder, setdata_preorder] = useState([]);
  const [preorder_count, setpreorder_count] = useState(0);
  const [start_date, setstart_date] = useState(moment().format("DD/MM/YYYY"));
  const [end_date, setend_date] = useState(moment().format("DD/MM/YYYY"));

  const [option_area, setoption_area] = useState([]);
  const [status_count, setstatus_count] = useState([]);

  const getDataPreOrder = async () => {
    try {
      let send_data = {
        end_date: "",
        order_no: "",
        start_date: "",
        status: [1, 2, 3],
        type: [0],
        service: ["car"],
      };

      let { data } = await Axios.post(
        `${api}/admin/order/filter?page=${1}&limit=${999999}&sort=${-1}`,
        send_data,
        header_token
      );

      // console.log("getDataPreOrder", data);
      if (data.orders !== null) {
        setdata_preorder(data.orders);
        setpreorder_count(data.orders.length);
      } else {
        setdata_preorder([]);
        setpreorder_count(0);
      }
    } catch (error) {
      console.log(error);
      console.log(error.response);
    }
  };

  const getOptionArea = async () => {
    try {
      let { data } = await Axios.get(`${api}/area`);
      let tmp = [];
      tmp.push({ value: "", label: "ทั้งหมด" });

      data.forEach((value) => {
        tmp.push({ value: value.id, label: value.name_th });
      });
      setoption_area(tmp);
    } catch (error) {
      console.log(error.response);
    }
  };

  const pushToLink = async (link) => {
    setTimeout(() => {
      push(link);
    }, 50);
  };

  const getStatusCount = async () => {
    try {
      const date = moment(start_date, "DD/MM/YYYY").format("YYYY-MM-DD")
      const endDate = moment(end_date, "DD/MM/YYYY").format("YYYY-MM-DD")

      let send_data = {
        end_date: endDate,
        start_date: date,
      };

      let { data } = await Axios.post(
        `${api}/admin/order/count/filter`,
        send_data,
        header_token
      );
      if (data == null) setstatus_count([])

      setstatus_count(data)
    } catch (error) {
      console.log(error);
      console.log(error.response);
    }
  }

  useEffect(() => {
    if (data_preorder.length <= 0) {
      getDataPreOrder();
    }
    getOptionArea();
    getStatusCount()
  }, []);

  useEffect(() => {
    getStatusCount()
    const intervalId = setInterval(() => {
      getStatusCount()
    }, 10000);

    return () => {
      clearInterval(intervalId);
    };
  }, [start_date, end_date]);


  const getCountWithStatus = (statusId) => {
    try {
      if (statusId == 'all') return status_count.reduce((old, current) => old + current.count, 0)
      const statusData = status_count.filter((value) => value.status == statusId);

      if (statusData.length == 0) return 0

      return statusData[0].count
    } catch (error) {
      return 0
    }
  }

  return (
    <div>
      <div className="dashboard-header d-flex flex-column grid-margin ">
        <div className="d-flex align-items-center justify-content-between flex-wrap">
          <div className="d-flex align-items-center">
            <h4 className="mb-0 font-weight-bold mr-3">รายการคำสั่งซื้อ</h4>
            <div className="d-none d-md-flex mt-2">
              <p className="text-muted mb-0 tx-13 cursor-pointer">หน้าหลัก</p>
              <i className="mdi mdi-chevron-right text-muted"></i>
              <p className="text-muted mb-0 tx-13 cursor-pointer">
                สร้างรายการใหม่
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="row mb-1" style={{ justifyContent: 'space-between' }}>
        <h3 className=" ml-3" style={{ marginTop: 10 }}>
          {moment(start_date, "DD/MM/YYYY").format("LL")}
        </h3>
        <div className="row" style={{ marginRight: '10px', alignItems: 'center' }}>
          <div>
            <DayPickerInput
              value={start_date}
              onDayChange={(e) => {
                setstart_date(moment(e, "DD/MM/YYYY").format("DD/MM/YYYY"));
              }}
              overlayComponent={CustomOverlay}
              style={{ width: "100%" }}
              inputProps={{
                style: {
                  width: "100%",
                },
              }}
              placeholder={"วัน/เดือน/ปี"}
              format="L"
              formatDate={formatDate}
              parseDate={parseDate}
              dayPickerProps={{
                locale: "th",
                localeUtils: MomentLocaleUtils,
              }}
            // inputProps={{
            //   style: {
            //     borderColor: "hsl(0,0%,80%)",
            //   },
            // }}
            />
          </div>
          <span style={{ marginLeft: '5px', marginRight: '5px' }}>ถึง</span>
          <div>
            <DayPickerInput
              value={end_date}
              onDayChange={(e) => {
                setend_date(moment(e, "DD/MM/YYYY").format("DD/MM/YYYY"));
              }}
              overlayComponent={CustomOverlay}
              style={{ width: "100%" }}
              inputProps={{
                style: {
                  width: "100%",
                },
              }}
              placeholder={"วัน/เดือน/ปี"}
              format="L"
              formatDate={formatDate}
              parseDate={parseDate}
              dayPickerProps={{
                locale: "th",
                localeUtils: MomentLocaleUtils,
              }}
            // inputProps={{
            //   style: {
            //     borderColor: "hsl(0,0%,80%)",
            //   },
            // }}
            />
          </div>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-12">
          <ul className="nav nav-pills" id="pills-tab" role="tablist">
            <li className="nav-item mb-1">
              <NavLink
                exact
                className={"nav-link "}
                activeClassName="active"
                to="/car_service_order"
              >
                ออเดอร์ใหม่
                <span className="badge badge-pill badge-danger ml-1">
                  {noti.car_service > 0 ? noti.car_service : ""}
                </span>
                <span className="badge badge-pill badge-primary ml-1">
                  {getCountWithStatus(1)}
                </span>
              </NavLink>
            </li>

            <li className="nav-item mb-1">
              <NavLink
                exact
                className={"nav-link "}
                activeClassName="active"
                to="/car_service_order/receive"
              >
                เข้ารับสินค้า
                <span className="badge badge-pill badge-primary ml-1">
                  {getCountWithStatus(2)}
                </span>
              </NavLink>
            </li>

            <li className="nav-item mb-1">
              <NavLink
                exact
                className={"nav-link "}
                activeClassName="active"
                to="/car_service_order/onprocess"
              >
                ดำเนินการ
                <span className="badge badge-pill badge-primary ml-1">
                  {getCountWithStatus(3)}
                </span>
              </NavLink>
            </li>

            <li className="nav-item mb-1">
              <NavLink
                exact
                className={"nav-link "}
                activeClassName="active"
                to="/car_service_order/sending"
              >
                กำลังจัดส่ง
                <span className="badge badge-pill badge-primary ml-1">
                  {getCountWithStatus(4)}
                </span>
              </NavLink>
            </li>

            <li className="nav-item mb-1">
              <NavLink
                exact
                className={"nav-link "}
                activeClassName="active"
                to="/car_service_order/success"
              >
                ส่งสำเร็จ
                <span className="badge badge-pill badge-primary ml-1">
                  {getCountWithStatus(5)}
                </span>
              </NavLink>
            </li>
            <li className="nav-item mb-1">
              <NavLink
                exact
                className={"nav-link "}
                activeClassName="active"
                to="/car_service_order/cancel"
              >
                ยกเลิก
                <span className="badge badge-pill badge-primary ml-1">
                  {getCountWithStatus(0)}
                </span>
              </NavLink>
            </li>
            <li className="nav-item mb-1">
              <NavLink
                exact
                className={"nav-link "}
                activeClassName="active"
                to="/car_service_order/unsuccess"
              >
                ไม่สำเร็จ
                <span className="badge badge-pill badge-primary ml-1">
                  {getCountWithStatus(6)}
                </span>
              </NavLink>
            </li>

            <li className="nav-item mb-1">
              <NavLink
                exact
                className={"nav-link "}
                activeClassName="active"
                to="/car_service_order/pre_order"
              >
                {data_preorder.length > 0 ? (
                  <div
                    style={{
                      width: 8,
                      height: 8,
                      borderRadius: 4,
                      backgroundColor: "red",
                      marginRight: 8,
                      display: "inline-block",
                    }}
                  />
                ) : (
                  ""
                )}
                ออเดอร์ล่วงหน้า
                <span className="badge badge-pill badge-primary ml-1">
                  {data_preorder.length}
                </span>
              </NavLink>
            </li>

            <li className="nav-item mb-1">
              <NavLink
                exact
                className={"nav-link "}
                activeClassName="active"
                to={`/car_service_order/history`}
              >
                ประวัติคำสั่งซื้อ
                <span className="badge badge-pill badge-primary ml-1">
                  {getCountWithStatus("all")}
                </span>
              </NavLink>
            </li>

            <li className="nav-item mb-1">
              <NavLink
                exact
                className={"nav-link "}
                activeClassName="active"
                to={`/car_service_order/add`}
              >
                สร้างใหม่
              </NavLink>
            </li>
          </ul>
        </div>
      </div>

      <Switch>
        <Route exact path={`/car_service_order`}>
          <OrderCarNew
            // data={data.filter((e) => e.status === 1)}
            statusOrder={[1]}
            pushToLink={pushToLink}
            optionArea={option_area}
            start_date={start_date}
            end_date={end_date}
          />
        </Route>

        <Route exact path={`/car_service_order/receive`}>
          <OrderCarReceive
            // data={data.filter((e) => e.status === 2)}
            statusOrder={[2]}
            pushToLink={pushToLink}
            optionArea={option_area}
            start_date={start_date}
            end_date={end_date}
          />
        </Route>

        <Route exact path={`/car_service_order/onprocess`}>
          <OrderCarOnprocess
            // data={data.filter((e) => e.status === 3)}
            statusOrder={[3]}
            pushToLink={pushToLink}
            optionArea={option_area}
            start_date={start_date}
            end_date={end_date}
          />
        </Route>

        <Route exact path={`/car_service_order/sending`}>
          <OrderCarSending
            // data={data.filter((e) => e.status === 4)}
            statusOrder={[4]}
            pushToLink={pushToLink}
            optionArea={option_area}
            start_date={start_date}
            end_date={end_date}
          />
        </Route>
        <Route exact path={`/car_service_order/success`}>
          <OrderCarSuccess
            // // data={data.filter((e) => e.status === 5)}
            statusOrder={[5]}
            pushToLink={pushToLink}
            optionArea={option_area}
            start_date={start_date}
            end_date={end_date}
          />
        </Route>
        <Route exact path={`/car_service_order/cancel`}>
          <OrderCarCancel
            // data={data.filter((e) => e.status === 0)}
            statusOrder={[0]}
            pushToLink={pushToLink}
            optionArea={option_area}
            start_date={start_date}
            end_date={end_date}
          />
        </Route>
        <Route exact path={`/car_service_order/unsuccess`}>
          <OrderCarUnsuccess
            // data={data.filter((e) => e.status === 6)}
            statusOrder={[6]}
            pushToLink={pushToLink}
            optionArea={option_area}
            start_date={start_date}
            end_date={end_date}
          />
        </Route>
        <Route exact path={`/car_service_order/pre_order`}>
          <OrderCarPreOrder
            data={data_preorder}
            pushToLink={pushToLink}
            optionArea={option_area}
          />
        </Route>
        <Route exact path={`/car_service_order/history`}>
          <OrderCarHistory
            statusOrder={[]}
            pushToLink={pushToLink}
            optionArea={option_area}
            start_date={start_date}
            end_date={end_date}
          />
        </Route>
        <Route exact path={`/car_service_order/add`}>
          <OrderCarCreate />
        </Route>
      </Switch>
    </div>
  );
};
